.productdisplay {
  display: flex;
  margin: 0 12vw;
}

.productdisplay-left {
  display: flex;
  gap: 1vw;
  position: sticky;
  top: 2vh;
  align-self: flex-start;
}

.productdisplay-img-list {
  display: flex;
  flex-direction: column;
  gap: 1.25vh;
}

.productdisplay-img-list > * {
  width: 6vw;
  height: 6vw;
  object-fit: cover;
  border-radius: 16px;
}

.productdisplay-img-list > *:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
  transition: 0.25s;
}

.productdisplay-img-list > *:active {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
  transform: scale(0.975);
  transition: 0.25s;
}

.productdisplay-img-list > .active {
  background-color: lightgrey;
  opacity: 0.65;
}

.product-images-expand {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  background-color: black;
  overflow: hidden;
  font-size: 1.25vw;
}

.product-images-expand-icon {
  color: white;
  z-index: 1; /* TODO - why is this above navbar? .headroom z-index is set to 1000 */
}

.product-images-expand:hover {
  background-color: grey;
}

.product-images-expand:active {
  background-color: #171717;
}

.product-images-expand img {
  filter: blur(2px);
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  border-radius: 16px;
}

.product-images-expand-icon {
  position: absolute;
  align-items: center;
  color: white;
}

.productdisplay-main-img {
  width: 24vw;
  height: 28vw;
  object-fit: cover;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.3);
  border-radius: 6.25%;
}

.productdisplay-right {
  margin: 0 0 0 2vw;
  gap: 2vh;
  display: flex;
  flex-direction: column;
}

.productdisplay-right h1 {
  color: #171717;
  font-size: 2rem;
}

.productdisplay-right hr {
  border: 1px solid #e9e9e9;
}

.productdisplay-right a {
  color: darkgrey;
  text-decoration: none;
  transition: 0.25s;
}

.productdisplay-right a:hover {
  color: grey;
}

.productdisplay-right a:active {
  color: orange;
}

.productdisplay-right-rating {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5vh;
  color: darkgrey;
}

.productdisplay-right-rating-default {
  color:lightgrey;
}

.productdisplay-right-rating-stars {
  color: lightgrey;
}

.productdisplay-right-rating-full {
  color: orange;
}

.productdisplay-right-rating-half {
  color: orange;
}

.productdisplay-right-discount {
  color: red;
  font-size: 1.2rem;
  font-weight: 700;
}

.productdisplay-right-prices {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;
}

.productdisplay-right-stock {
  color: lightgrey;
  font-size: 1rem;
  font-weight: 500;
}

.productdisplay-right-stock.low-stock {
  font-weight: 700;
  color: green;
}

.productdisplay-right-stock.out-of-stock {
  color: red;
}

.productdisplay-right-price {
  font-size: 1.2rem;
  font-weight: 700;
  color: #171717;
}

.productdisplay-right-price.reduced {
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
  color: red;
}

.productdisplay-right-price-old {
  color: darkgrey;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: line-through;
}

.productdisplay-right-detail-item {
  margin: 0.5vh 0;
  font-size: 1rem;
}

.productdisplay-right-detail-item strong {
  color: #3a3a3a;
}

.productdisplay-right-detail-item span {
  color: #171717;
}

.productdisplay-tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.productdisplay-tag {
  background-color: grey;
  padding: 0.4rem 0.8rem; 
  border-radius: 20px; 
  display: inline-block;
  font-size: 0.9rem; 
  color: white;
  text-transform: capitalize;
}

.productdisplay-right-about {
  font-size: 1rem;
}

.productdisplay-right-about p {
  font-weight: 400;
}

.productdisplay-right-colors {
  display: flex;
  gap: 1vw;
}

.productdisplay-right-colors div {
  padding: 0.5vw 1vw;
  border: none;
  border-radius: 5px;
  min-width: 32px;
  background-color: #f0f0f0;
  color: #171717;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
}

.productdisplay-right-colors div:hover {
  background-color: #e5e5e5;
  transform: scale(1.025);
  transition: 0.25s;
}

.productdisplay-right-colors div:active {
  background-color: #d5d5d5;
  transform: scale(0.975);
  transition: 0.25s;
}

.productdisplay-right-size a {
  color: darkgrey;
  text-decoration: none;
  font-weight: 700;
  transition: 0.25s;
}

.productdisplay-right-size a:hover {
  color: #667eea;
}

.productdisplay-right-size a:active {
  color: #764ba2
}

.productdisplay-right-sizes {
  display: flex;
  gap: 1vw;
}

.productdisplay-right-sizes div {
  padding: 0.5vw 1vw;
  border: none;
  border-radius: 5px;
  min-width: 32px;
  background-color: #f0f0f0;
  color: #171717;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
}

.productdisplay-right-sizes div:hover {
  background-color: #e5e5e5;
  transform: scale(1.025);
  transition: 0.25s;
}

.productdisplay-right-sizes div:active {
  background-color: #d5d5d5;
  transform: scale(0.975);
  transition: 0.25s;
}

.productdisplay-right-category-buttons {
  display: flex;
  gap: 1vw;
}

.productdisplay-right button {
  padding: 2vh 2vw;
  min-height: 32px;
  border: none;
  border-radius: 5px;
  background-color: darkgrey;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.productdisplay-right button:hover {
  background-color: grey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
  transition: 0.25s;
}

.productdisplay-right button:active {
  background-color: #171717;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  transform: scale(0.975);
  transition: 0.25s;
}

.productdisplay-right-category-buttons-favourite {
  background-color: grey; /* Default background color */
}

.productdisplay-right-category-buttons-favourite.in-favorites {
  background-color: red;
}

.productdisplay-right-category-buttons-favourite:hover {
  background-color: red !important;
}

.productdisplay-right-category-buttons-favourite:active {
  background-color: darkred !important;
}

.productdisplay-right-category-buttons-cart {
  background-color: #171717 !important;
}

.productdisplay-right-category-buttons-cart:hover {
  background: linear-gradient(135deg, #667eea, #764ba2) !important;
}

.productdisplay-right-filters {
  color: grey;
}

.productdisplay-right-filters span {
  font-weight: 600;
}

.productdisplay-right-already {
  color: grey;
  font-size: 1rem;
  font-weight: 700;
}

@media only screen and (max-width: 1400px) {
  .productdisplay {
    flex-direction: column;
    gap: 4vh;
  }

  .productdisplay-left {
    position: relative;
    margin: auto;
    top: 0;
  }
}

@media only screen and (max-width: 1250px) {
  .productdisplay-img-list > * {
    width: 12vw;
    height: 12vw;
    object-fit: cover;
  }

  .product-images-expand {
    font-size: 2.5vw;
  }

  .productdisplay-main-img {
    width: 45vw;
    height: 56vw;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .productdisplay {
    margin: 4vh 8vw 0 8vw; /* Breadcrumb is display:none on mobile, this removes its top margin; add  here */
  }

  .productdisplay-left {
    margin: auto;
    gap: 2vw;
  }

  .productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  .productdisplay-img-list > * {
    width: 16vw;
    height: 16vw;
    object-fit: cover;
  }

  .product-images-expand {
    font-size: 4vw;
  }

  .productdisplay-main-img {
    width: calc(
      100% - 4vw
    ); /* Adjust width to account for padding and border */
    width: 64vw;
    height: 72vw;
    object-fit: cover;
  }

  .productdisplay-right {
    margin-top: 2vh;
  }

  .productdisplay-right h1 {
    font-size: 1.4rem;
  }

  .productdisplay-right-rating {
    gap: 1vw;
  }

  .productdisplay-right-sizes div {
    width: 100%;
  }

  .productdisplay-right-category-buttons {
    flex-direction: column;
    gap: 2vw;
  }

  .productdisplay-right-category-buttons button {
    height: 6vh;
    gap: 1vw;
  }

  .productdisplay-right-filters {
    display: none;
  }
}
