.cartitems {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  color: #171717;
}

.cartitems * {
  text-decoration: none;
}

.cartitems-item-right-link {
  color: #171717; /* Or any other color you prefer */
}

.cartitems hr {
  border: none;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  margin-top: 16px;
}

.cartitems h1 {
  color: #171717;
}

.cartitems-message {
  color: lightgrey;
  font-weight: 600;
  margin-top: 16px;
}

.cartitems-item {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.cartitems-item-left {
  margin-right: 16px;
}

.cartitems-item-left a {
  position: relative;
  width: fit-content;
}

.cartitems-item-left-image {
  width: 160px;
  height: 150px;
  object-fit: cover;
  border-radius: 12.5%;
}

.cartitems-item-left-reduced {
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0));
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 4px 0;
}

.cartitems-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.cartitems-item-right-title {
  font-size: 20px;
  font-weight: 600;
}

.cartitems-item-right-info b {
  font-weight: 600;
  color: grey;
}

.cartitems-item-right-reduced {
  color: red;
  font-weight: 700;
}

.cartitems-item-right-calculations {
  width: 100%;
}

.cartitems-item-right-cost {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.cartitems-item-price-old {
  font-size: 0.9rem;
  color: #999;
  text-decoration: line-through;
}

.cartitems-item-price.is-reduced {
  position: relative;
  color: red;
  font-weight: 700;
}

.cartitems-right-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cartitems-right-actions * {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.cartitems-item-right-quantity {
  font-weight: 700;
  color: #171717;
}

.cartitems-item-right button {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  transition: 0.25s;
  color: grey;
}

.cartitems-item-right button:hover {
  color: #171717;
  background-color: #e0e0e0;
}

.cartitems-item-right button:active {
  background-color: #d0d0d0;
}

.cartitems-item-right button:focus {
  outline: none;
}

/* .cartitems-item-right-delete:hover {
  background-color: red !important;
  color: white;
} */

@media screen and (max-width: 768px) {
  .cartitems {
    padding: 0;
    gap: 0;
  }
  .cartitems-list {
    margin: 0;
  }
  .cartitems-item {
    flex-direction: column;
    margin: 0;
  }
  .cartitems-item-left {
    margin: 0;
  }
  .cartitems-item-right {
    margin-top: 8px;
    gap: 0;
  }
}
