.reviewinput-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reviewinput-input form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reviewinput-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.reviewinput-input label {
  font-size: 1.2rem;
  font-weight: 700;
  color: grey;
  margin-bottom: 0.25rem;
}

.reviewinput-input textarea, .reviewinput-input input {
  padding: 0.8rem;
  border: none;
  background-color: #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  transition: background-color 0.3s ease;
  width: 100%;
}

/* Keep background white if there is value or the field is focused */
.reviewinput-input textarea:not(:placeholder-shown), .reviewinput-input input:not(:placeholder-shown) {
  background-color: white;
}

.reviewinput-input textarea:hover, .reviewinput-input input:hover {
  background-color: #e5e5e5;
}

.reviewinput-input textarea:focus, .reviewinput-input input:focus {
  background-color: white;
  outline: none;
}

.reviewinput-input ::placeholder {
  color: darkgrey;
}

.reviewinput-images-list {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.reviewinput-images img {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 8px;
  display: block;
}

.reviewinput-image-preview {
  position: relative;
}

.reviewinput-image-preview img {
  object-fit: cover;
  border-radius: 8px;
}

.reviewinput-image-remove {
  position: absolute;
  top: 4px;
  right: 4px;
  background: grey;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.65;
}

.reviewinput-image-remove:hover {
  background: #333;
  transition: 0.3s ease;
}

.reviewinput-image-placeholder {
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  opacity: 0.65;
  box-sizing: border-box;
  outline: 2px dashed darkgrey;
}

.reviewinput-image-placeholder .plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  opacity: 0;
  color: white;
}

.reviewinput-image-placeholder:hover .plus-icon {
  opacity: 1;
}

.reviewinput-image-placeholder:hover {
  opacity: 0.85;
}

.reviewinput-image-placeholder:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.reviewinput-submit {
  padding: 12px 2vw;
  border: none;
  border-radius: 8px;
  background-color: grey;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s ease, transform 0.25s ease, box-shadow 0.25s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
}

.reviewinput-submit:hover {
  background-color: #333;
}

.reviewinput-submit:active {
  background-color: #171717;
  transform: scale(0.975);
}


@media screen and (max-width: 768px) {
  .reviewinput-submit {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
  }
}
